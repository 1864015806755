import React from 'react';
import { renderToString } from 'react-dom/server';
import L from 'leaflet';
import Marker from './marker';
import MarkerMini from './marker-mini';
import User from './user';
import UserOff from './user-off';

const UserIcon = fill => {
  return new L.Icon({
    iconUrl: `data:image/svg+xml;utf8;base64,${btoa(
      renderToString(<User fill={fill} />),
    )}`,
    iconRetinaUrl: `data:image/svg+xml;utf8;base64,${btoa(
      renderToString(<User fill={fill} />),
    )}`,
    iconAnchor: [15, 15],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
    className: 'user',
  });
};

const UserOffIcon = fill => {
  return new L.Icon({
    iconUrl: `data:image/svg+xml;utf8;base64,${btoa(
      renderToString(<UserOff fill={fill} />),
    )}`,
    iconRetinaUrl: `data:image/svg+xml;utf8;base64,${btoa(
      renderToString(<UserOff fill={fill} />),
    )}`,
    iconAnchor: [15, 15],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
    className: 'user-off',
  });
};

const MarkerIcon = (fill, zIndexOffset) => {
  const icon = new L.Icon({
    iconUrl: `data:image/svg+xml;utf8;base64,${btoa(
      renderToString(<Marker fill={fill} />),
    )}`,
    iconRetinaUrl: `data:image/svg+xml;utf8;base64,${btoa(
      renderToString(<Marker fill={fill} />),
    )}`,
    iconAnchor: [15, 29],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
    className: `marker ${zIndexOffset !== undefined ? 'z-index-1000' : ''}`,
  });

  return icon;
};

const MiniIcon = fill => {
  return new L.Icon({
    iconUrl: `data:image/svg+xml;utf8;base64,${btoa(
      renderToString(<MarkerMini fill={fill} />),
    )}`,
    iconRetinaUrl: `data:image/svg+xml;utf8;base64,${btoa(
      renderToString(<MarkerMini fill={fill} />),
    )}`,
    iconAnchor: [15, 15],
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 30],
    className: 'marker-mini',
  });
};

export { UserIcon, UserOffIcon, MarkerIcon, MiniIcon };
