import React from 'react';

const User = ({ fill = 'black' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={fill}
        d="M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4M12,19C10.1,19 8.3,18.2 7.1,16.9C5.9,15.6 5,13.9 5,12C5,10.1 5.8,8.3 7.1,7.1C8.4,5.9 10.1,5 12,5C13.9,5 15.7,5.8 16.9,7.1C18.1,8.4 19,10.1 19,12C19,13.9 18.2,15.7 16.9,16.9C15.6,18.1 13.9,19 12,19Z"
      />
    </svg>
  );
};

export default User;
