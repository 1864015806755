import styled from 'styled-components';

const MapWrapper = styled.div`
  background-color: red;
  height: ${props => props.height};
  width: ${props => props.width};
  z-index: 1;

  &:-moz-broken {
    display: none;
  }

  & .leaflet-marker-icon.marker-cluster {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 18px;
    font-weight: bold;

    &.marker-cluster-small {
      background-color: rgba(181, 226, 140, 1);
    }

    &.marker-cluster-medium {
      background-color: rgba(241, 211, 87, 1);
    }

    &.marker-cluster-large {
      background-color: rgba(241, 128, 23, 1);
    }
  }

  & .leaflet-marker-icon.marker-cluster div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  /*markers style*/
  .user {
    fill: red;
  }

  .user-off {
    fill: red;
  }

  .marker {
    fill: red;
  }

  .marker-off {
    fill: red;
  }

  .marker-mini {
    fill: red;
  }

  .z-index-1000 {
    z-index: 1000 !important;
  }
`;

export default MapWrapper;
