import React from "react";

const MarkerMini = ({ fill = "black" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="50" width="50">
      <circle
        cx="25"
        cy="25"
        r="6"
        stroke="black"
        strokeWidth="0.5"
        fill={`${fill}`}
      />
    </svg>
  );
};

export default MarkerMini;
